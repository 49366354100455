import React from 'react';
import ReactDOM from 'react-dom';

// https://react-select.com/home
import Select from 'react-select';

class MultipleSelect extends React.Component {

  state = {
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    let selectMirror = document.querySelector('select[name="' + this.props.name + '"'),
        selection = selectedOption.map( (sel) => sel.value );
    this.setState({ selectedOption }, () =>
      this.state.selectedOption
    );
    for (let i = 0; i < selectMirror.options.length; i++) {
      const option = selectMirror.options[i];
      option.selected = selection.includes(parseInt(option.value));
    }
  };

  render() {
    const selectedOption = this.state.selectedOption ?? this.props.registered;

    return (
      <Select
        value={ selectedOption }
        onChange={ this.handleChange }
        options={ this.props.options }
        name={ this.props.name }
        isMulti={ true }
        noOptionsMessage= { () => "Aucune option sélectionnée" }
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'var(--grey-light)',
            primary: 'var(--black)',
          },
        })}
      />
    );
  }
}

const selects = document.querySelectorAll('.multipleSelect');

selects.forEach(select => {
  let options = select.dataset.options,
      registered = select.dataset.registered,
      name = select.dataset.name;
  options ? options = JSON.parse(options) : '{}';
  registered ? registered = JSON.parse(registered) : registered = [];
  options = options.map( (option) => JSON.parse(option) );
  registered = registered.map( (regis) => JSON.parse(regis) );

  ReactDOM.render(<MultipleSelect name= { name } options = { options } registered={ registered } />, select);
});