import React from 'react';
import ReactDOM from 'react-dom';
import { Editor } from '@tinymce/tinymce-react';

class AdminEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue ?? '',
        };
        this.editorRef = React.createRef();
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({
                value: this.props.initialValue ?? ''
            })
        }
    }

    componentDidMount() {
        if (this.props.tagName) {
            this.setState({
                tagName: this.props.tagName
            });
        }
        if (this.props.height > 0) {
            this.setState({
                height: this.props.height
            });
        }
    }

    handleEditorChange(value, editor) {
        this.setState({
            value
        });
    }

     render() {
        return (
          <Editor 
            value = {this.state.value}
            apiKey='evhn3jr60nk1rkuas30ew8iehceuophclf394tcto150k53h'
            textareaName = {this.props.tagName}
            onEditorChange = {this.handleEditorChange}
            onInit = { (evt, editor) => this.editorRef.current = editor }
            init = {
                {
                    height: this.props.height ?? 800,
                    plugins: 'advlist lists table wordcount code autolink link image emoticons',
                    toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify advlist | ' +
                        'bullist numlist outdent indent | link image emoticons | print preview media fullscreen | ' +
                        'forecolor backcolor | code',
                    menubar: 'favs file edit view insert format tools table',
                    automatic_uploads: true,
                    images_upload_url: '/api/tinymce-upload',
                    images_upload_credentials: true,
                }
            }
            />
        )
    }
}
let TextEditors = document.querySelectorAll('.TextEditor');
if (TextEditors.length > 0) {
    TextEditors.forEach(TextEditor => {
        let data = JSON.parse(TextEditor.dataset.editor);

        ReactDOM.render( 
            <AdminEditor 
                tagName = { data.tagName }
                initialValue = { data.content }
                height = { data.height }
            />, TextEditor
        );
    });
}
